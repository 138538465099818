<div class="page">
  <div class="wrapper">
    <div class="container">
      <div class="header">
        <img
          src="/assets/images/logo-stacked.svg"
          alt="RacingMedia.io"
          class="logo animate__animated animate__flipInY"
        />
      </div>

      <div class="content">
        <p>
          Helping racing drivers and teams to market themselves, add value to
          partners, and attract new sponsors
        </p>

        <h2>Register your interest</h2>

        <p class="form-intro">
          Enter your email address below to stay up to date with our product
          launch plans, and to register your interest in being a beta tester.
        </p>

        <link
          href="//cdn-images.mailchimp.com/embedcode/classic-10_7_dtp.css"
          rel="stylesheet"
          type="text/css"
        />

        <div id="mc_embed_signup">
          <form
            action="https://racingmedia.us14.list-manage.com/subscribe/post?u=51fdf92c51913216423d822d2&amp;id=07252df35f"
            method="post"
            id="mc-embedded-subscribe-form"
            name="mc-embedded-subscribe-form"
            class="validate"
            target="_blank"
            noValidate
          >
            <div id="mc_embed_signup_scroll">
              <div class="indicates-required">
                <span class="asterisk">*</span> indicates required
              </div>
              <div class="mc-field-group">
                <label htmlFor="mce-EMAIL">
                  Email Address <span class="asterisk">*</span>
                </label>
                <input
                  type="email"
                  name="EMAIL"
                  class="required email"
                  id="mce-EMAIL"
                />
              </div>
              <div id="mce-responses" class="clear foot">
                <div class="response" id="mce-error-response"></div>
                <div class="response" id="mce-success-response"></div>
              </div>
              <div style="position: absolute; left: -5000px" aria-hidden="true">
                <input
                  type="text"
                  name="b_51fdf92c51913216423d822d2_07252df35f"
                  tabIndex="-1"
                />
              </div>
              <div class="optionalParent">
                <div class="clear foot">
                  <input
                    type="submit"
                    value="Subscribe"
                    name="subscribe"
                    id="mc-embedded-subscribe"
                    class="button"
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
