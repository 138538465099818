<app-page [verticallyCentred]="true">
  <h1>Unauthorised</h1>

  <p>You do not have access to the requested page.</p>

  <p>
    <a mat-raised-button color="primary" routerLink="/home" class="home-link"
      >Return to Home Page</a
    >
  </p>
</app-page>
