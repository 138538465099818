<button
  mat-stroked-button
  class="change-display-name-btn"
  (click)="copyToClipboard()"
  [disabled]="!enabled"
>
  <mat-icon
    aria-hidden="false"
    aria-label="Copy device ID to clipboard"
    fontIcon="content_copy"
  ></mat-icon>
  Copy to clipboard
</button>
