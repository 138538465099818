import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AutoLoginAllRoutesGuard } from 'angular-auth-oidc-client';
import { ForbiddenComponent } from './forbidden/forbidden.component';
import { HomeComponent } from './home/home.component';
import { LoginCallbackComponent } from './login-callback/login-callback.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },

  // Public "shop front" routes
  {
    path: 'home',
    component: HomeComponent,
  },

  // Device code (add device to user account, requires login)
  {
    path: 'device',
    canLoad: [AutoLoginAllRoutesGuard],
    loadChildren: () =>
      import('./device/device.module').then((m) => m.DeviceModule),
  },

  // Account pages for logged in users
  {
    path: 'account',
    canLoad: [AutoLoginAllRoutesGuard],
    loadChildren: () =>
      import('./account/account.module').then((m) => m.AccountModule),
  },

  // Generic utility routes
  {
    path: 'forbidden',
    component: ForbiddenComponent,
    canActivate: [AutoLoginAllRoutesGuard],
  },
  { path: 'unauthorized', component: UnauthorizedComponent },
  {
    path: 'login-callback',
    component: LoginCallbackComponent,
  },
  {
    path: '**',
    pathMatch: 'full',
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
