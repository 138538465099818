<app-page [verticallyCentred]="true">
  <h1>You've taken a wrong turn&hellip;</h1>

  <p>The page your looking for wasn't found.</p>

  <p>
    <a mat-raised-button color="primary" routerLink="/home" class="home-link"
      >Return to Home Page</a
    >
  </p>
</app-page>
