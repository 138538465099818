<div class="page" [class.vertically-centred]="verticallyCentred">
  <div class="wrapper">
    <div class="container">
      <div class="header">
        <app-logo></app-logo>
      </div>

      <div class="content">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>
