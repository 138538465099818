import { Component, Input } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

enum State {
  Idle,
  Copying,
}

@Component({
  selector: 'app-copy-to-clipboard',
  templateUrl: './copy-to-clipboard.component.html',
  styleUrls: ['./copy-to-clipboard.component.scss'],
})
export class CopyToClipboardComponent {
  @Input() text: string | undefined;
  @Input() description: string | undefined;

  private state: State = State.Idle;

  constructor(private snackbar: MatSnackBar) {}

  get enabled() {
    return this.state === State.Idle;
  }

  async copyToClipboard() {
    if (!this.text) {
      return;
    }

    try {
      this.state = State.Copying;

      await navigator.clipboard.writeText(this.text);

      this.showSuccessMessage();
      this.resetState();
    } catch (err) {
      console.error('Failed to copy value to clipboard', err);

      this.showFailureMessage();
      this.resetState();
    }
  }

  private resetState() {
    setTimeout(() => {
      this.state = State.Idle;
    }, 750);
  }

  private showSuccessMessage() {
    const message = this.description
      ? `Copied ${this.description} to clipboard`
      : 'Copied to clipboard';

    this.snackbar.open(message, undefined, { duration: 1000 });
  }

  private showFailureMessage() {
    const message = this.description
      ? `Could not copy ${this.description} to clipboard`
      : 'Could not copy to clipboard';

    this.snackbar.open(message, undefined, { duration: 1000 });
  }
}
